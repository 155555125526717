import BreadCrumbs from '@components/Breadcrumbs';
import Alert from '@material-ui/lab/Alert';
import { apiClient } from '@services/api';
import { useEffect, useState } from 'react';
import ContentStep1 from './finish/ContentStep1';
import ContentStep2 from './finish/ContentStep2';
import FinishTable from './finish/FinishTable';
import ReviewPage from './finish/ReviewPage';

const FinishBuy = (props) => {
  document.title = `Finalizar compra - ${process.env.REACT_APP_NAME}`
  const [step, setStep] = useState(1)
  const [saleId, setSaleId] = useState(null);
  const [error, setError] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [regions, setRegions] = useState([]);
  const [regionsTravel, setRegionsTravel] = useState([]);
  const [client, setClient] = useState({
    addresses: [{ region_name: 'TRASLADO' }],
    customer_id: null,
    email: null,
    document: null,
    name: null,
    phone: null,
    hotel_name: null,
    hotel_address: null,
    room_number: null,
    customer_language: null,
    observations: null,
    havePartner: false,
    partner: null,
    partnerEmail: null,
    derived: false,
    partner_type: null,
    partner_seller_id: null
  })

  const [reserve, setReserve] = useState({
    id: null,
    payment_status: null,
    payment_type: null,
    payment_method: null,
    customer_paid_amount: 0,
    payment_proof: null,
    proof: null,
    proof_name: null,
    hotel: '',
    hotel_address: '',
    room_number: '',
    proofs: []
  })
  const [travels, setTravels] = useState([]);
  const [cart, setCart] = useState([]);

  let url = '/user/cart';

  const changeClient = (ev, idAddress) => {
    if (ev?.target?.value) {
      let value = ev.target.value
      if (idAddress) {
        let addresses = []
        client.addresses.forEach((element, index) => {
          if (element.id === idAddress) {
            element[ev.target.name] = value
          }
          addresses.push(element)
        });
        setClient({
          ...client,
          addresses: addresses
        })
      } else {
        setClient({
          ...client,
          [ev.target.name]: value
        })
      }
    } else {
      setClient({
        ...client,
        [ev.target.name]: ''
      })
    }
  }

  const changeClientByEmail = (data) => {
    const clientData = {
      ...client,
      addresses: data.addresses,
      customer_id: data.customer_id,
      customer_language: data.customer_language,
      document: data.document,
      email: data.email,
      name: data.name,
      hotel_name: data.hotel_name,
      hotel_address: data.hotel_address,
      room_number: data.room_number,
      observations: data.observations,
      phone: data.phone,
      partner: data.partner,
      partnerEmail: data.partnerEmail,
      havePartner: data.havePartner,
      partner_seller_id: data.partner_seller_id,
      partner_type: data.partner_type
    }

    setClient(clientData)

    data.addresses.forEach((address, index) => {
      if (address?.region_id === null) {
        address.region_name = 'TRASLADO'
        client.addresses[0] = address
      } else {
        if (client.addresses[index] && client.addresses[index].region_id === address.region_id) {
          let result = regions?.find(({ id }) => id === address.region_id)
          address.region_name = result.name
          client.addresses[index] = address
        }
      }
    });

    if (regions?.length >= client.addresses?.length) {
      regions.forEach((region, index) => {
        client.addresses.forEach((address, index) => {
          let result = regions?.find(({ id }) => id === address.region_id)

          if (!result) {
            client.addresses.push({ "region_id": region.id, "region_name": region.name })
          }
        });
      });
    }

    setReserve({
      ...reserve,
      hotel: data.hotel_name,
      hotel_address: data.hotel_address,
      room_number: data.room_number,
    })

  }

  const changeClientByPartner = (data) => {
    const clientData = {
      ...client,
      partner: data.partner,
      partnerEmail: data.partnerEmail,
      partner_seller_id: data.partner_seller_id,
      partner_type: data.partner_type
    }
    setClient(clientData)
  }

  const changeObservations = (ev) => {
    let value = ev.target.value

    setClient({
      ...client,
      ['observations']: value
    })
    setReserve({
      ...reserve,
      ['customer_observations']: value
    })
  }

  const changeClientAddress = (ev) => {
    let value = ev.target.value
    let addresses = client.addresses

    if (ev.target.index !== undefined && addresses[ev.target.index] !== null) {
      addresses[ev.target.index][ev.target.name] = value

      if (ev.target.region_id && ev.target.region_id !== '') {
        addresses[ev.target.index]['region_id'] = ev.target.region_id
      }
      if (ev.target.region_name && ev.target.region_name !== '') {
        addresses[ev.target.index]['region_name'] = ev.target.region_name
      }
    }

    setClient({
      ...client,
      addresses: addresses
    })
  }

  const notDerived = data => {
    const clientData = {
      ...client,
      email: data.email,
      derived: data.derived
    }
    setClient(clientData)
  }

  const changeClientCheckbox = (ev) => {
    let value = null
    if (ev.target.value === "true") {
      value = true
    } else {
      value = false
    }

    setClient({
      ...client,
      partner: null,
      [ev.target.name]: value
    })
  }
  const changeReserve = (ev) => {
    let value = ev.target.value
    if (ev.target.name === "havePartner") {
      value = ev.target.value === "true" ? true : false;
    }
    setReserve({
      ...reserve,
      [ev.target.name]: value
    })
  }

  const changeProof = (file, name) => {
    setReserve({
      ...reserve,
      proof: file,
      proof_name: name
    })
  }

  const addProof = (file, name, idx) => {
    const proofs = reserve.proofs
    const newProof = {
      file: file,
      name: name
    }
    proofs.push(newProof)
    setReserve({
      ...reserve,
      proofs: proofs
    })
  }

  const removeProof = (idx) => {
    const proofs = reserve.proofs
    proofs.splice(idx, 1);
    setReserve({
      ...reserve,
      proofs: proofs
    })
  }

  const bradecrumbsConfig = {
    tour: [
      {
        title: 'Nova reserva',
        active: true,
        route: '/admin/reservas'
      },
      {
        title: 'Passeio',
        active: true,
        route: '/admin/reservas/passeios'
      },
      {
        title: `Comprar`,
      }
    ],
    transfer: [
      {
        title: 'Nova reserva',
        active: true,
        route: '/admin/reservas'
      },
      {
        title: 'Traslados',
        active: true,
        route: '/admin/reservas/traslados'
      },
      {
        title: `Comprar`,
      }
    ],
    combo: [
      {
        title: 'Nova reserva',
        active: true,
        route: '/admin/reservas'
      },
      {
        title: 'Combos',
        active: true,
        route: '/admin/reservas/combos'
      },
      {
        title: `Comprar`,
      }
    ],
    cart: [
      {
        title: 'Carrinho',
        active: true,
        route: '/admin/carrinho'
      },
      {
        title: 'Comprar'
      }
    ]
  }

  const createReserve = () => {
    let total = null;
    setLoading(true);
    if (reserve.payment_status === "pending" && !(Number(reserve.customer_paid_amount) > 0)) {
      setReserve({
        ...reserve,
        payment_method: null
      });
    }

    if (!(reserve.payment_status === "pending") && !reserve.payment_method) {
      return false;
    }

    const totalBruto = cart.reduce((accumulator, currentValue) => accumulator + Number(currentValue.total_amount), 0)
    total = totalBruto

    console.log( {
      company: `${process.env.REACT_APP_COMPANY_KEY}`,
      customer_address: client.addresses,
      customer_name: client.name,
      customer_document: client.document,
      customer_email: client.email,
      customer_phone: client.phone,
      customer_hotel: reserve.hotel || client.hotel_name,
      customer_hotel_address: reserve.hotel_address || client.hotel_address,
      customer_room_number: reserve?.room_number || client.room_number,
      customer_observations: client.observations,
      customer_language: client.customer_language,
      partner: client.partner,
      partner_seller_id: client.partner_seller_id,
      partner_type: client.partner_type,
      derived: client.derived,
      payment_method: reserve.payment_method,
      customer_paid_amount: reserve.payment_type === "total" ? total : Number(reserve.customer_paid_amount),
      reference: reserve.payment_proof,
      travels: travels,
      total: total,
      pre_sale_id: cart[0]?.pre_sale_id
    })
    apiClient
      .httpInstance.post("/reservations/register_travels",
        {
          company: `${process.env.REACT_APP_COMPANY_KEY}`,
          customer_address: client.addresses,
          customer_name: client.name,
          customer_document: client.document,
          customer_email: client.email,
          customer_phone: client.phone,
          customer_hotel: reserve.hotel || client.hotel_name,
          customer_hotel_address: reserve.hotel_address || client.hotel_address,
          customer_room_number: reserve?.room_number || client.room_number,
          customer_observations: client.observations,
          customer_language: client.customer_language,
          partner: client.partner,
          partner_seller_id: client.partner_seller_id,
          partner_type: client.partner_type,
          derived: client.derived,
          payment_method: reserve.payment_method,
          customer_paid_amount: reserve.payment_type === "total" ? total : Number(reserve.customer_paid_amount),
          reference: reserve.payment_proof,
          travels: travels,
          total: total,
          pre_sale_id: cart[0]?.pre_sale_id
        }
      )
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          if (reserve.proofs.length > 0) {
            sendProof(response.data.id, reserve.proofs)
          } else {
            setSaleId(response.data.id);
            setLoading(false);
            setStep(3)
          }

          setPaymentStatus(response.data.payment_status);
        }
      })
      .catch(error => {
        if (error) {
          setError(true);
          setLoading(false);
          setErrorMessage(error?.response?.message || "Erro inesperado, tente novamente mais tarde")
          setTimeout(() => { setError(false) }, 4000);
          setTimeout(() => { setErrorMessage(null) }, 4000);

        }
      });
  }

  const sendProof = (id, proofs) => {
    proofs.map((proof, idx) => {
      const formData = new FormData()
      formData.append('proof', proof.file)
      formData.append('proof_name', proof.name)

      apiClient
        .httpInstance.post(`/sales/${id}/proof`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then(response => {
          if (response.status === 200 || response.status === 201) {
            if (proofs.length === idx + 1) {
              setSaleId(id);
              setLoading(false);
              setStep(3)
            }
          }
        })
        .catch(error => {
          if (error) {
            setError(true);
            setLoading(false);
            var responseMessage = error?.response?.message ? error?.response?.message : null
            setErrorMessage(responseMessage || "Erro ao adicionar o comprovante")
            setTimeout(() => { setError(false) }, 4000);
            setTimeout(() => { setErrorMessage(null) }, 4000);

          }
        });
    })
  }

  const getCartProducts = url => {
    if (props?.budget) {
      setCart(props.reservationData.cart)
      props.reservationData.cart.map((cartValue) => {
        let data = {}
        if (cartValue.type === 'transfer') {
          data = {
            travel_id: cartValue.travel_id,
            type: 'transfer',
            region_id: cartValue.travel_region,
            cupos: cartValue.cupos,
            transfer_type: cartValue.transfer_type,
            value: cartValue.value,
            value_cost: cartValue.value_cost,
            value_to: cartValue.value_to,
            value_of: cartValue.value_of,
            value_dolars: cartValue.value_dolars,
            value_dolars_cost: cartValue.value_dolars_cost,
            tour_date: cartValue.tour_date,
            discount_total_amount: cartValue.discount_total_amount,
            total_amount: Number(cartValue.total_amount),
            is_group: cartValue.is_group,
            flight_code: cartValue.flight_code,
            flight_time: cartValue.flight_time,
            combo_id: cartValue.combo_id
          }
        } else {
          data = {
            travel_id: cartValue.travel_id,
            tour_id: cartValue.tour_id,
            type: cartValue.type,
            region_id: cartValue.travel_region,
            cupos: cartValue.cupos,
            tour_date: cartValue.tour_date,
            operational_cost: /* client.partner_seller_id ? */ (cartValue.adults_total * cartValue.adults_operational_cost) + (cartValue.kids_total * cartValue.kids_operational_cost),
            adults_total: cartValue.adults_total,
            adults_total_amount: cartValue.adults_total_amount,
            adults_operational_cost: cartValue.adults_operational_cost,
            adults_operational_cost_sale: cartValue.adults_operational_cost_sale,
            adults_discount: cartValue.adults_discount,
            kids_total: cartValue.kids_total,
            kids_total_amount: cartValue.kids_total_amount,
            kids_operational_cost: cartValue.kids_operational_cost,
            kids_operational_cost_sale: cartValue.kids_operational_cost_sale,
            kids_discount: cartValue.kids_discount,
            discount_total_amount: cartValue.discount_total_amount,
            total_amount: Number(cartValue.total_amount),
            is_group: cartValue.is_group,
            combo_id: cartValue.combo_id
          }
        }

        //Recolhe ids das regiões para lista de endereços do cliente
        if (!regionsTravel.includes(cartValue.travel_region)) {
          regionsTravel.push(cartValue.travel_region)
        }

        if (cartValue.type === 'transfer') {
          if (!regionsTravel.includes('transfer')) {
            regionsTravel.push('transfer')
          }
        }

        setTravels(oldArray => [...oldArray, data])
      })
      return
    }

    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setCart(response.data)
        response.data.map((cartValue) => {
          let data = {}
          if (cartValue.type === 'transfer') {
            data = {
              travel_id: cartValue.travel_id,
              type: 'transfer',
              region_id: cartValue.travel_region,
              cupos: cartValue.cupos,
              transfer_type: cartValue.transfer_type,
              value: cartValue.value,
              value_cost: cartValue.value_cost,
              value_to: cartValue.value_to,
              value_of: cartValue.value_of,
              value_dolars: cartValue.value_dolars,
              value_dolars_cost: cartValue.value_dolars_cost,
              tour_date: cartValue.tour_date,
              discount_total_amount: cartValue.discount_total_amount,
              total_amount: Number(cartValue.total_amount),
              is_group: cartValue.is_group,
              flight_code: cartValue.flight_code,
              flight_time: cartValue.flight_time,
              combo_id: cartValue.combo_id
            }
          } else {
            data = {
              travel_id: cartValue.travel_id,
              tour_id: cartValue.tour_id,
              type: cartValue.type,
              region_id: cartValue.travel_region,
              cupos: cartValue.cupos,
              tour_date: cartValue.tour_date,
              operational_cost: (cartValue.adults_total * cartValue.adults_operational_cost) + (cartValue.kids_total * cartValue.kids_operational_cost),
              adults_total: cartValue.adults_total,
              adults_total_amount: cartValue.adults_total_amount,
              adults_operational_cost: cartValue.adults_operational_cost,
              adults_operational_cost_sale: cartValue.adults_operational_cost_sale,
              adults_discount: cartValue.adults_discount,
              kids_total: cartValue.kids_total,
              kids_total_amount: cartValue.kids_total_amount,
              kids_operational_cost: cartValue.kids_operational_cost,
              kids_operational_cost_sale: cartValue.kids_operational_cost_sale,
              kids_discount: cartValue.kids_discount,
              discount_total_amount: cartValue.discount_total_amount,
              total_amount: Number(cartValue.total_amount),
              is_group: cartValue.is_group,
              combo_id: cartValue.combo_id
            }
          }

          //Recolhe ids das regiões para lista de endereços do cliente
          if (!regionsTravel.includes(cartValue.travel_region)) {
            regionsTravel.push(cartValue.travel_region)
          }

          if (cartValue.type === 'transfer') {
            if (!regionsTravel.includes('transfer')) {
              regionsTravel.push('transfer')
            }
          }

          setTravels(oldArray => [...oldArray, data])

        })
      }
    });
  }

  const onlyLetterAndNumber = (e) => {
    e.preventDefault();
    const regex = new RegExp("^[a-zA-Z0-9]+$");
    const value = e.target.value
    if (value.match(regex)) {
      setClient({
        ...client,
        document: value
      })
    } else if (value === "") {
      setClient({
        ...client,
        document: null
      })
    }
  }

  const getRegionData = async () => {
    await apiClient.httpInstance.get('/regions').then(response => {
      if (response.status === 200) {
        let regions = response.data.sort(function (a, b) {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        })
        setRegions(regions);

        regions.forEach((region, idx) => {
          if (regionsTravel.includes(String(region.id) || region.id)) {
            let result = client?.addresses?.find(({ region_id }) => region_id === region.id);
            if (result === undefined) {
              client.addresses.push({
                id: '',
                region_id: region.id,
                region_name: region.name,
                customer_id: '',
                hotel: '',
                hotel_address: '',
                room_number: ''
              })
            }
          } else if (regionsTravel.includes('transfer')) {
            client.addresses[0] = {
              id: '',
              region_id: '',
              region_name: 'TRASLADO',
              customer_id: '',
              hotel: '',
              hotel_address: '',
              room_number: ''
            }
          } else {
            client.addresses[idx] = {
              id: '',
              region_id: region.id,
              region_name: region.name,
              customer_id: '',
              hotel: '',
              hotel_address: '',
              room_number: ''
            }
          }
        });

        if (travels[0]?.type === 'combo') {
          let regions_combo = []
          regions.forEach(element => {
            regions_combo.push(element.id)
          });
          setRegionsTravel(regions_combo)
        }

      }
    });
  }

  useEffect(() => {
    getRegionData();
  }, [travels]);
  useEffect(() => {
    getCartProducts(url);
  }, [url]);

  useEffect(() => {
    getRegionData();
  }, [travels]);

  return <>
    {step !== 3 ?
      <>
        {error && (
          <Alert className="mb-5" variant="filled" severity="error">
            {errorMessage}
          </Alert>
        )}
        <BreadCrumbs items={bradecrumbsConfig[props.type]} />
        <div className="px-7 pt-6">
          <div className="flex items-center justify-between mt-2 text-2xl text-blue">
            <span>Comprar</span>
          </div>
          <FinishTable data={{ cart, travels }} type="cart" />
          {step === 1
            ?
            <>
            {console.log(regionsTravel, regions)}
              <ContentStep1
                {...props}
                backPage={props.backPage}
                changeStep={setStep}
                changeClient={changeClient}
                changeReserve={changeReserve}
                changeClientAddress={changeClientAddress}
                changeObservations={changeObservations}
                changeClientCheckbox={changeClientCheckbox}
                client={client}
                reserve={reserve}
                regionsTravel={regionsTravel}
                onlyLetterAndNumber={onlyLetterAndNumber}
                changeClientByEmail={changeClientByEmail}
                changeClientByPartner={changeClientByPartner}
                notDerived={notDerived}
              />
            </>
            :
            <>
              <ContentStep2
                {...props}
                changeStep={setStep}
                changeReserve={changeReserve}
                addProof={addProof}
                removeProof={removeProof}
                reserve={reserve}
                create={createReserve}
                loading={loading}
              />
            </>
          }
        </div>
      </>
      :
      <ReviewPage {...props} saleId={saleId} paymentStatus={paymentStatus} reservationData={{ cart, travels }} reserve={reserve} client={client} type="cart" />
    }
  </>
}

export default FinishBuy;